export default [
  {
    component: 'CNavItem',
    name: 'Управляющая компания',
    to: '/companies',
    icon: 'cil-home',
  },

  {
    component: 'CNavItem',
    name: 'Жилые комплексы',
    to: '/complex',
    icon: 'cil-building',
  },

  {
    component: 'CNavItem',
    name: 'Заявки',
    to: '/requests',
    icon: 'cil-clipboard',
  },

  {
    component: 'CNavItem',
    name: 'Баланс и начисления',
    to: '/personalAccount',
    icon: 'cil-dollar',
  },

  {
    component: 'CNavItem',
    name: 'Счетчики',
    to: '/counters',
    icon: 'cil-calculator',
  },

  {
    component: 'CNavItem',
    name: 'Документы',
    to: '/documents',
    icon: 'cil-description',
  },

  {
    component: 'CNavItem',
    name: 'Конструктор уведомлений',
    to: '/presets',
    icon: 'cil-speech',
  },

  {
    component: 'CNavItem',
    name: 'Часто задаваемые вопросы',
    to: '/faq',
    icon: 'cil-info',
  },

  {
    component: 'CNavItem',
    name: 'Галерея изображений Новостей',
    to: '/gallery',
    icon: 'cil-newspaper',
  },

  {
    component: 'CNavItem',
    name: 'Логи импорта 1С',
    to: '/logs',
    icon: 'cilFeaturedPlaylist',
  },

  {
    component: 'CNavGroup',
    name: 'Маркетплейс',
    icon: 'cil-cart',
    items: [
      {
        component: 'CNavItem',
        name: 'Каталог',
        to: '/marketplace/catalog',
        icon: 'cilBasket',
      },
      {
        component: 'CNavItem',
        name: 'Единицы измерения',
        to: '/marketplace/units',
        icon: 'cilBalanceScale',
      },
      {
        component: 'CNavItem',
        name: 'Точки самовывоза',
        to: '/marketplace/points',
        icon: 'cilAudio',
      },
    ],
  },
];
