import axiosInstance from "@/api/axiosInstance";

const getBreadcrumbs = (id) => {
  return axiosInstance.get('/public/marketplace/categories', {
    headers: {
      Authorization: localStorage.getItem('accessToken')
    },
    params: {'findWhere[childId]': id}
  });
};

export {getBreadcrumbs};
