import {defineStore} from "pinia";
import {ref} from "vue";
import {getBreadcrumbs} from "@/store/catalogBreadcrumb/api";
import {getProductsAPI} from "@/store/product/api";

export const useCatalogBreadcrumbStore = defineStore('catalogBreadcrumb', () => {
  const breadcrumbs = ref([]);
  const product = ref({});
  const isLoading = ref(false);

  const fetchBreadcrumbs = async (id) => {
    if (!id) return;

    isLoading.value = true;
    try {
      const res = await getBreadcrumbs(id);

      if (res.status === 200) {
        breadcrumbs.value = res.data.data;
      }
    } catch (e) {
      console.error(e);
    } finally {
      isLoading.value = false;
    }
  };

  const fetchProductById = async (productId) => {
    isLoading.value = true;

    try {
      const res = await getProductsAPI({
        'findWhere[id]': productId
      });

      if (res.status === 200) {
        product.value = res.data.data[0];
      }
    } catch (e) {
      console.error(e);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    fetchProductById,
    fetchBreadcrumbs,
    product,
    breadcrumbs,
    isLoading
  };
});
