import axiosInstance from "@/api/axiosInstance";

const getProductsAPI = (params) => {
  return axiosInstance.get('/private/marketplace/products', {
    headers: {
      Authorization: localStorage.getItem('accessToken')
    },
    params
  });
};

const deleteProductAPI = (variationId) => {
  return axiosInstance.delete(`/private/marketplace/products/${variationId}`, {
    headers: {
      Authorization: localStorage.getItem('accessToken')
    }
  });
};

const createProductAPI = (data) => {
  return axiosInstance.post('/private/marketplace/products/', data, {
    headers: {
      Authorization: localStorage.getItem('accessToken'),
      'Content-Type': 'multipart/form-data'
    }
  });
};

const updateProductAPI = (data, id) => {
  return axiosInstance.patch(`/private/marketplace/products/${id}`, data, {
    headers: {
      Authorization: localStorage.getItem('accessToken'),
      'Content-Type': 'multipart/form-data'
    }
  });
};

export {getProductsAPI, deleteProductAPI, createProductAPI, updateProductAPI};
