<template>
  <CBreadcrumb class="d-md-down-none me-auto mb-0">
    <template
      v-if="isMarketplace">
      <Spinner v-if="isLoading"/>
      <template v-else>
        <CBreadcrumbItem href="/companies">
          C&U
        </CBreadcrumbItem>
        <CBreadcrumbItem href="/marketplace">
          Маркетплейс
        </CBreadcrumbItem>
        <CBreadcrumbItem v-if="isUnits">
          Единицы измерения
        </CBreadcrumbItem>
        <CBreadcrumbItem v-if="isDeliveries">
          Варианты доставки
        </CBreadcrumbItem>
        <CBreadcrumbItem v-if="isPoints">
          Точки самовывоза
        </CBreadcrumbItem>
        <template
          v-if="isCatalog">
          <CBreadcrumbItem :href="computedCatalogLink">
            Каталог
          </CBreadcrumbItem>
          <CBreadcrumbItem v-if='computedVariationLink'>
            {{ catalogBreadcrumbs[0].name }}
          </CBreadcrumbItem>
          <template v-if="computedCategoryId">
            <CBreadcrumbItem
              v-for="(item, index) in catalogBreadcrumbs"
              :key="index"
              :href="computedCategoryLink(index, item)"
            >
              {{ item.name }}
            </CBreadcrumbItem>
          </template>
        </template>
      </template>
    </template>
    <template v-else>
      <CBreadcrumbItem
        v-for="(item, index) in breadcrumbs"
        :key="item"
        :href="computedBaseLink(index, item)"
        @click="onClickBreadcrumb"
      >
        {{ item.name }}
      </CBreadcrumbItem>
    </template>
  </CBreadcrumb>
</template>

<script setup>
import router from '@/router';
import {CBreadcrumb, CBreadcrumbItem} from "@coreui/vue";
import {useBreadcrumbStore} from "@/store/breadcrumb";
import {storeToRefs} from "pinia";
import {computed, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {useCatalogBreadcrumbStore} from "@/store/catalogBreadcrumb";
import Spinner from "@/components/spinner/spinner.vue";

const route = useRoute();

const breadcrumbStore = useBreadcrumbStore();
const {breadcrumbs, prevBreadcrumbs} = storeToRefs(breadcrumbStore);

const breadcrumbCatalog = useCatalogBreadcrumbStore();
const {breadcrumbs: catalogBreadcrumbs, isLoading} = storeToRefs(breadcrumbCatalog);

const computedCategoryId = computed(() => {
  return route.params.categoryId;
});

const computedfullPath = computed(() => {
  return route.fullPath;
});

const computedCatalogLink = computed(() => {
  return computedCategoryId.value || route.query.all ? '/marketplace/catalog' : '';
});

const computedVariationLink = computed(() => {
  return route.query.all && catalogBreadcrumbs.value.length;
});

const computedCategoryLink = (index, item) => {
  return index + 1 !== catalogBreadcrumbs.value.length ? item.object === 'Category' ? `/marketplace/catalog/categories/${item.realId}?categoryIdHash=${item.id}` : `/marketplace/catalog/categories/${catalogBreadcrumbs[index - 1]?.realId}/products/${item.realId}?productIdHash=${item.id}` : '';
};

const computedBaseLink = (index, item) => {
  return breadcrumbs.value[breadcrumbs.value.length - 1].name === item.name ? '' : `${index > 2 ? setPath(index) : item.path}`;
};

const isMarketplace = computed(() => {
  return computedfullPath.value.includes('marketplace');
});

const isUnits = computed(() => {
  return computedfullPath.value.includes('units');
});

const isDeliveries = computed(() => {
  return computedfullPath.value.includes('deliveries');
});

const isPoints = computed(() => {
  return computedfullPath.value.includes('points');
});

const isCatalog = computed(() => {
  return computedfullPath.value.includes('catalog') || computedfullPath.value.includes('variations');
});

const realBreadcrumbs = ref();

const getBreadcrumbs = () => router.currentRoute.value.matched.map((route) => {
  return {
    active: route.path === router.currentRoute.value.fullPath,
    name: route.meta.title ?? route.name,
    path: `${route.path.includes(':') ? route.path.split(':')[route.path.split(':').length - 2].includes('Id') ? route.path.split(':')[route.path.split(':').length - 2].split('Id')[1] + route.props.default.id : route.path.split(':')[route.path.split(':').length - 2] + route.props.default.id : route.path}`,
    id: route.props.default?.id
  };
});

const setPath = (index) => {
  let res = breadcrumbs.value[2].path;
  for (let i = 3; i <= index; i++) {
    res += breadcrumbs.value[i].path;
  }

  return res;
};

const onClickBreadcrumb = () => {
  breadcrumbs.value = getBreadcrumbs();
};

router.beforeEach(() => {
  prevBreadcrumbs.value = [...breadcrumbs.value];
});

router.afterEach(() => {
  breadcrumbs.value = getBreadcrumbs();
});

onMounted(() => {
  if (!sessionStorage.getItem("breadcrumb")) {
    breadcrumbs.value = getBreadcrumbs();
  } else {
    realBreadcrumbs.value = getBreadcrumbs();

    if (breadcrumbs.value.length >= realBreadcrumbs.value.length) {
      breadcrumbs.value = breadcrumbs.value.slice(0, realBreadcrumbs.value.length);
    } else {
      breadcrumbs.value = prevBreadcrumbs.value.slice(0, realBreadcrumbs.value.length);

      if (prevBreadcrumbs.value.length < realBreadcrumbs.value.length) {
        breadcrumbs.value.push(realBreadcrumbs.value[Object.keys(realBreadcrumbs.value).pop()]);
      }
    }
  }
});
</script>

<style lang="scss" scoped src="./appBreadcrumb.scss"/>
