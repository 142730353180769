import {createRouter, createWebHistory} from 'vue-router';

import defaultLayout from '@/layouts/defaultLayout.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {title: 'C&U'},
    component: defaultLayout,
    redirect: '/companies',
    children: [
      {
        path: '/companies',
        name: 'companies',
        meta: {title: 'Управляющая компания'},
        component: () => import(/* webpackChunkName: "companies" */ '@/pages/companies/companies.vue'),
        children: [
          {
            path: ':companyId',
            name: 'company',
            meta: {title: 'Компания'},
            component: () => import(/* webpackChunkName: "companyDetail" */ '@/pages/companies/companiesCompany/companiesCompany.vue'),
          }
        ]
      },
      {
        path: '/complex/',
        name: 'complex',
        meta: {title: 'Жилые комплексы'},
        component: () => import(/* webpackChunkName: "complexes" */ '@/pages/complexes/complexes.vue'),
        children: [
          {
            path: 'houses/:complexId/',
            name: 'complex-houses',
            meta: {title: 'Дома'},
            props: {id: null},
            component: () => import(/* webpackChunkName: "complexHouses" */ '@/pages/complexes/complexesHouses/complexesHouses.vue'),
            children: [
              {
                path: 'enterways/:houseId/',
                name: 'complex-entryways',
                meta: {title: 'Подъезды'},
                props: {id: null},
                component: () => import(/* webpackChunkName: "entryways" */ '@/pages/complexes/complexesEntryways/complexesEntryways.vue'),
                children: [
                  {
                    path: 'floors/:entrywayId/',
                    name: 'complex-floors',
                    meta: {title: 'Этажи'},
                    props: {id: null},
                    component: () => import(/* webpackChunkName: "floors" */ '@/pages/complexes/complexesFloors/complexesFloors.vue'),
                    children: [
                      {
                        path: 'premises/:floorId/',
                        name: 'complex-premises',
                        meta: {title: 'Помещения'},
                        props: {id: null},
                        component: () => import(/* webpackChunkName: "premises" */ '@/pages/complexes/complexesPremises/complexesPremises.vue'),
                      }
                    ]
                  }
                ]
              },
            ]
          }
        ]
      },
      {
        path: '/requests/',
        name: 'requests',
        meta: {title: 'Заявки'},
        component: () => import(/* webpackChunkName: "requests" */ '@/pages/requests/requests.vue'),
        children: [
          {
            path: ':placeId',
            name: 'problems',
            meta: {title: 'Проблемы'},
            component: () => import(/* webpackChunkName: "problemDetail" */ '@/pages/requests/requestsProblem/requestsProblem.vue'),
          }
        ]
      },
      {
        path: '/personalAccount/',
        name: 'personalAccount',
        meta: {title: 'Лицевые счета'},
        component: () => import(/* webpackChunkName: "personalAccount" */ '@/pages/personalAccount/personalAccount.vue'),
        children: [
          {
            path: ':accountId',
            name: 'personalAccountInfo',
            meta: {title: 'Информация о лицевом счете'},
            component: () => import(/* webpackChunkName: "accountDetail" */ '@/pages/personalAccount/personalAccountInfo/personalAccountInfo.vue'),
          }
        ]
      },
      {
        path: '/counters/',
        name: 'counters',
        meta: {title: 'Счетчики'},
        component: () => import(/* webpackChunkName: "counters" */ '@/pages/counters/counters.vue'),
        children: [
          {
            path: ':counterId',
            name: 'readings',
            meta: {title: 'Показания'},
            component: () => import(/* webpackChunkName: "readings" */ '@/pages/counters/countersReadings/countersReadings.vue'),
          }
        ]
      },
      {
        path: '/documents',
        name: 'documents',
        meta: {title: 'Документы'},
        component: () => import(/* webpackChunkName: "documents" */ '@/pages/documents/documents.vue'),
      },
      {
        path: '/presets',
        name: 'constructor',
        meta: {title: 'Конструктор уведомлений'},
        component: () => import(/* webpackChunkName: "constructor" */ '@/pages/constructor/constructor.vue'),
      },
      {
        path: '/faq/',
        name: 'faqGroups',
        meta: {title: 'Группы вопросов'},
        component: () => import(/* webpackChunkName: "faqGroups" */ '@/pages/faq/faqGroups/faqGroups.vue'),
        children: [
          {
            path: ':groupId',
            name: 'faqQuestions',
            meta: {title: 'Вопросы группы'},
            component: () => import(/* webpackChunkName: "faqQuestions" */ '@/pages/faq/faqQuestions/faqQuestions.vue'),
          },
        ]
      },
      {
        path: '/gallery',
        name: 'gallery',
        meta: {title: 'Галерея изоброжений Новости'},
        component: () => import(/* webpackChunkName: "gallery" */ '@/pages/gallery/gallery.vue'),
      },
      {
        path: '/logs',
        name: 'log',
        meta: {title: 'Логи импорта 1С'},
        component: () => import(/* webpackChunkName: "logs" */ '@/pages/logs/logs.vue'),
      },
      {
        path: '/marketplace',
        name: 'marketplace',
        meta: {title: 'Маркетплейс'},
        redirect: '/marketplace/catalog',
        children: [
          {
            path: 'catalog',
            name: 'catalog',
            meta: {title: 'Каталог'},
            component: () => import(/* webpackChunkName: "catalog" */ '@/pages/catalog/catalog.vue'),
            children: [
              {
                path: 'categories/:categoryId',
                name: 'categories',
                meta: {title: 'Подкатегории'},
                component: () => import(/* webpackChunkName: "categoryDetail" */ '@/pages/categories/categoriesCategory/categoriesCategory.vue'),
                children: [
                  {
                    path: 'products/:productId',
                    name: 'product',
                    meta: {title: 'Продукты'},
                    component: () => import(/* webpackChunkName: "productDetail" */ '@/pages/products/products.vue'),
                    children: [
                      {
                        path: 'variations/:variationId',
                        name: 'variations',
                        meta: {title: 'Вариации'},
                        component: () => import(/* webpackChunkName: "productDetail" */ '@/pages/variations/variations.vue'),
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            path: 'variations/:variationId',
            name: 'variationsAll',
            meta: {title: 'Вариации'},
            component: () => import(/* webpackChunkName: "productDetail" */ '@/pages/variations/variations.vue'),
          },
          {
            path: 'units',
            name: 'units',
            meta: {title: 'Единицы измерения'},
            component: () => import(/* webpackChunkName: "units" */ '@/pages/units/units.vue'),
          },
          {
            path: 'deliveries',
            name: 'deliveries',
            meta: {title: 'Варианты доставки'},
            component: () => import(/* webpackChunkName: "deliveries" */ '@/pages/deliveries/deliveries.vue'),
          },
          {
            path: 'points',
            name: 'points',
            meta: {title: 'Точки самовывоза'},
            component: () => import(/* webpackChunkName: "points" */ '@/pages/points/points.vue'),
          }
        ]
      }
    ]
  },
  {
    path: '/auth',
    name: 'auth',
    meta: {title: 'Вход'},
    component: () => import(/* webpackChunkName: "auth" */ '@/pages/auth/auth.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {top: 0};
  },
});

export default router;
